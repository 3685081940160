* {
    padding: 0;
    margin: 0;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

body {
    background-color: #F7F6F2 !important;
}

.body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #333;
    background: #F7F6F2 !important;
    font-weight: 400;
    font-size: 1rem;
    min-width: 300px;
    overflow-x: visible;
    width: 100%;
    max-width: 100%;
    font-display: swap
    /*min-height: 100vh;*/
}

.App {
    text-align: left;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* custom.css */
.navbar {
    --bs-navbar-padding-y: 0rem;
}

.navbar-nav {
    font-size: 1rem;
}

.navbar-nav .nav-item .nav-link {
    padding-left: 15px;
}

.navbar-nav .nav-item .nav-link:active {
    color: black; /* Change to your desired text color on hover */
    background-color: #f0f0f0; /* Change to your desired background color on hover */
    padding-left: 15px;
}

.navbar-nav .nav-item .title {
    margin-top: 10px;
    font-weight: 700;
    font-size: 1.15rem;
}

.navbar-nav .nav-item .small_title {
    margin-top: 10px;
    font-weight: 700;
    font-size: 1.05rem;
}

.navbar-nav .nav-item .sub_title {
    margin-left: 5px;
}


.navbar-collapse {
    flex-grow: 0;
}

.shadow {
    width: 100%;
    background-image: url(/images/navigation_sprite1.py8271.png);
    background-repeat: repeat-x;
    background-position: -1px -148px;
    height: 2px;
}

.hidden {
    display: none;
}

.shape {
    width: 0;
    height: 100px;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 50px solid black;
    border-right: 50px solid black;
    transform: rotate(90deg);
}

div.oversized_input_wait {
    margin-top: 150px;
    margin-left: auto;
    margin-bottom: 100px;
    margin-right: auto;
    font-size: 30px;
    color: #b3b3b3;
    display: table;
}

div.oversized_input_wait div {
    display: table-cell;
    vertical-align: middle;
    padding-bottom: 5px;
}

.offcanvas-body {
    padding: 0 !important;
}

.offcanvas {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

/*******************************************************************************************/
/*************************************** STRING network ************************************/
/*******************************************************************************************/

.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


#stringEmbedded {
    position: relative;
    width: 100%;
    height: max(60vw, 40vh);
    margin: 0 auto;
}

svg {
    vertical-align: top;
    /*position: absolute;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

svg .rect {
    fill: gold;
    stroke: steelblue;
    stroke-width: 5px;
}

/*******************************************************************************************/
/************************************* enrichment table ************************************/
/*******************************************************************************************/

.responsiveTable {
    width: 100%;
}

.responsiveTable td .tdBefore {
    display: none;
}

@media screen and (max-width: 20em) {
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
        display: block;
    }

    .responsiveTable thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        border-bottom: 2px solid #333;
    }

    .responsiveTable tbody tr {
        border: 1px solid #000;
        padding: .25em;
    }

    .responsiveTable td.pivoted {
        /* Behave like a "row" */
        border: none !important;
        position: relative;
        padding-left: calc(50% + 10px) !important;
        text-align: left !important;
        overflow-wrap: break-word;

        /* Now like a table header */
        max-width: 50px; /* Adjust the width as needed */
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .responsiveTable td .tdBefore {
        /* Now like a table header */
        position: absolute;
        display: block;

        /* Top/left values mimic padding */
        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 700;
    }
}

div.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 5px;
    min-width: 300px;
}

.enrichment_table {
    max-width: 96%;
    width: 96%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px;
    border: 1px solid #A0A0A0;
    background-color: white;
}

.enrichment_table_body.div {
    border: 1px solid #A0A0A0;
    border-top: none;
    background-color: white;
}

.enrichment_count_in_network_cell {
    padding: 0;
}

/* table arrows */
.enrichment_table div.not_expanded {
    margin-right: 8px;
    margin-top: 4px;
    height: 18px;
    width: 19px;
    background-repeat: no-repeat;
    background-position: 1px -2px;
    float: left;
    background-image: url(/images/tabs_icons_v3.svg);
}

.enrichment_table .title {
    display: flex;
    align-items: center; /* vertically center */
    height: 40px; /* Adjust as needed */
    color: #4D4D4D;
}

.enrichment_table div.fully_expanded {
    margin-right: 8px;
    margin-top: 4px;
    height: 18px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: 1px -25px;
    float: left;
    background-image: url(/images/tabs_icons_v3.svg);
}

.enrichment_table_more {
    font-size: clamp(1rem, 2vw, 1rem);
    font-style: italic;
    text-align: right;
    width: 96%;
    cursor: default;
}

.term_search_cell {
    max-width: 200px;
}

.term_search_cell a {
    text-decoration: underline !important;
    color: #333;
    white-space: nowrap;
}

.description_enrichment_cell a {
    text-decoration: underline !important;
    color: #333;
    white-space: nowrap;
}

.term_enrichment_cell_separator {
    background: #EFEFEF;
    border-bottom:1px solid rgb(160, 160, 160);
}

.term_enrichment_cell_separator a{
    color: #333;
    padding-left: 3vw;
}

.term_enrichment_cell a {
    text-decoration: underline !important;
    color: #333;
    white-space: nowrap;
}

.term_enrichment_cell {
    display: flex;
    align-items: center; /* Vertically center the items */
}

.rounded-header {
    padding-left: 5px;
    background: #EFEFEF;
    border-radius: 5px;
}


.rounded_header_last {
    padding-left: 5px;
    background: #EFEFEF;
    border-radius: 5px;
}


.last-row {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.description_enrichment_cell {
    padding: 3vw 3vw 0 3vw ;
    font-size: clamp(1rem, 2vw, 1.2rem);
    /*word-break: break-all;*/
    /*font-weight: bold;*/
}

.disambiguation_enrichment_cell {
    padding: 3vw 0 3vw 0;
    font-size: clamp(1rem, 2vw, 1.2rem);
}


.enrichment_count_in_network_cell a {
    color: #333;
}

.enrichment_table_selected {
    background: #E6CACA !important;
}

.enrichment_color_container.flat_bubble {
    height: max(7vh, 7vw);
    width: max(7vh, 7vw);
    /*border: 3px solid #A0A0A0;*/
    border-radius: max(3.5vh, 3.5vw);
    background-color: #A0A0A0;
}

.enrichment_color_container.inner_flat_bubble {
    height: max(6vh, 6vw);
    width: max(6vh, 6vw);
    /*border: 3px solid #A0A0A0;*/
    border-radius: max(3vh, 3vw);
    background-color: #EFEFEF;
}

.enrichment_table_selected .enrichment_color_container {
    opacity: 0.4;
}

.enrichment_bubble_container {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: max(4vh, 4vw);
    height: max(4vh, 4vw);
}

.enrichment_table_small_title {
    color: #555;
    width: 96%;
    cursor: default;
    /*font-size: 0.95rem;*/
}

/*sorting drop down menu*/
select.th_sortable span {
    color: red;
    text-decoration: underline;
    text-decoration-color: #C0C0C0;
    padding-left: 10px;
    padding-right: 5px;
    background: url(/images/navigation_sprite2.png) no-repeat 0 -310px;
}

div.th_sortable .asc {
    background: url(/images/navigation_sprite2.png) no-repeat left -356px;
}

div.th_sortable .desc {
    background: url(/images/navigation_sprite2.png) no-repeat left -333px;
}

span.emphasis_keyword {
    background-color: #D0D5F3;
}

/*******************************************************************************************/
/****************************************** Main Header ************************************/
/*******************************************************************************************/

.main_header {
    z-index: 10;
    position: relative;
}

.main_header .container {
    margin-right: auto;
    margin-left: auto;
    z-index: 10;
    position: relative;
    height: 86px;
}

.main_header .shadow {
    width: 100%;
    background-image: url(/images/navigation_sprite1.png);
    background-repeat: repeat-x;
    background-position: -1px -148px;
    height: 2px;
}

.main_header h1 {
    font-size: 45px;
    height: 86px;
    display: block;
    float: left;
    outline: none;
}

#string_logo_left {
    width: 35px;
    height: 35px;
    display: block;
    float: left;
    text-indent: -9999px;
    outline: none;
    border: none;
    background: url(/images/string_logo_2015_compact_left.png) no-repeat center center;
    cursor: pointer;
    margin-left: 1vw;
}

#string_logo_left_home {
    width: 90px;
    height: 130px;
    /*background: url(/images/navigation_sprite1.png) no-repeat center center;*/
    background: url(/images/string_logo.png) no-repeat center center;
    /*background-position: 0 0;*/
    /*padding-bottom: 11vh;*/
    margin-bottom: -30px;
    /*margin-left: 3vw;*/
}

@supports (background-size: 570.4545454545%) {
    #string_logo_left {
        background-image: url(/images/navigation_sprite1.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 570.4545454545%;
    }
}

.carousel_container {
    padding-bottom: 10vh;
}

#string_logo_right {
    width: 88px;
    height: 30px;
    display: block;
    float: left;
    text-indent: -9999px;
    outline: none;
    background: url(/images/string_logo_2015_compact_right.png) no-repeat center center;
    cursor: pointer;
    margin-left: 0;
}

/*#string_desktop_link {*/
/*    width: 74px;*/
/*    height: 60px;*/
/*    display: block;*/
/*    background: url(/images/desktop.png) no-repeat center center;*/
/*    background-position: -42px -47px;*/
/*    background-size: 339%;*/
/*}*/

@supports (background-size: 212.7118644%) {
    #string_logo_right {
        background-image: url(/images/navigation_sprite1.png);
        background-repeat: no-repeat;
        background-position: -33px 0;
        background-size: 212.7118644%;
    }
}

.main_nav ul {
    text-align: left;
    float: right;
}

.main_nav ul li {
    display: inline;
}

.main_nav ul li a {
    height: 40px;
    line-height: 40px;
    padding-top: 23px;
    padding-bottom: 2px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 15px;
    display: block;
    color: #4d4d4d;
    border-bottom: none;
    background-color: rgba(247, 246, 242, 1);
    transition: all .15s ease-in-out .1s;
}

.main_nav ul li div {
    float: left;
}

.main_nav ul li div.active {
    background-image: url(/images/navigation_sprite1.png), url(/images/navigation_sprite1.png), url(/images/navigation_sprite1.png);
    background-repeat: no-repeat, no-repeat, repeat-x;
    background-position: left -150px, right -150px, -177px -150px;
}

.main_nav ul li div:hover {
    background-image: url(/images/navigation_sprite1.png), url(/images/navigation_sprite1.png), url(/images/navigation_sprite1.png);
    background-repeat: no-repeat, no-repeat, repeat-x;
    background-position: left -227px, right -227px, -177px -227px;
}

.main_nav ul li div:hover a {
    background-color: rgba(247, 246, 242, 0);
    transition: all .15s ease-in-out .1s;
}

.main_nav ul li div.active a, .main_nav ul li div.active:hover a {
    background-color: rgba(247, 246, 242, 0);
    transition: all .15s ease-in-out .1s;
}

/*******************************************************************************************/
/****************************************** Top Bar ****************************************/
/*******************************************************************************************/

.top_bar {
    background: #2c2e34;
    width: 100%;
    min-width: 300px;
    min-height: 3vh;
    z-index: 1000;
    color: #fff;
}

.top_bar .left {
    left: 0;
    top: 0;
}

.top_bar .center {
    text-align: center;
}

.top_bar .right {
    right: 0;
    top: 0;
}

.top_bar .left {
    position: absolute;
    left: 0;
    top: 0;
}

.string_linkout {
    padding-bottom: 5px;
}

.string_linkout a {
    color: #333;
    white-space: nowrap;
    margin: 1%;
}

/*******************************************************************************************/
/*************************************** Top Bar List **************************************/
/*******************************************************************************************/

ul.top_bar_list {
    text-align: right;
    margin-bottom: 0;
}

ul.top_bar_list li {
    vertical-align: middle;
    display: inline;
    text-align: right;
    font-size: 0.8rem;
    color: #999;
    padding-left: 10px;
    padding-right: 10px;
    background-position: right -108px;
    background-repeat: no-repeat;
    margin-left: -1px;
    font-weight: 700;
}

ul.top_bar_list li:last-child {
    background: none;
}

ul.top_bar_list li.last {
    background: none;
}

ul.top_bar_list li.header {
    background: none;
    color: #999;
    font-weight: 400;
    padding-right: 0;
}

ul.top_bar_list li.remark {
    color: #999;
    font-weight: 400;
    font-size: 13px;
}

ul.top_bar_list li a {
    color: #999;
    display: inline-block;
    text-transform: uppercase;
    padding-right: 5px;
}

/*******************************************************************************************/
/***************************************** Nav Bar  ****************************************/
/*******************************************************************************************/

.navbar-toggler-no-border {
    border: transparent !important;
}

.navbar-toggler-icon {
    width: 2em !important;
    height: 2em !important;
}

.nav-main {
    /*    aline all items horisantly using flex */
    display: flex;
    align-items: center;
    padding: 0 5px;
    height: 10vh;
    width: 100%;
    min-width: 300px;
    z-index: 1000;
}

/*******************************************************************************************/
/***************************************** Start Page  *************************************/
/*******************************************************************************************/

.home_container {
    padding: 0 10vw 0 10vw;
    overflow-y: hidden;
    overflow-x: auto;
    max-height: 80vh;
}

.home_title {
    text-transform: uppercase;
    font-size: 1.8rem;
    color: #333;
    margin-left: 3vw;
}

.site_info {
    padding: 3vw;
    margin-bottom: 5vh;
    color: #333;
    font-size: clamp(1rem, 2vw, 1.2rem);
}


.site_cover {
    background-color: #2f78a2;
    color: #fff;
    text-align: center;
    padding-top: 10px;
    min-width: 300px;
}

.site_cover h2 {
    font-size: clamp(1rem, 1rem + 3vw, 2.5rem);
    font-weight: 400;
    margin: 7vh 0.5vh 7vh 0.5vh;
    color: #fff;
    line-height: 1.7em;
}

.site_cover button {
    margin-top: 5vw;
    margin-bottom: 10vw;
    width: clamp(50vw, 5vw, 100vw);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: clamp(1rem, 1rem + 2vw, 2rem);
    transition: all .2s ease;
    text-decoration: none;
    display: inline-block;
}

.site_cover button:active {
    background: #a0a0a0;
}

#GBC_logo {
    background-image: url(/images/navigation_sprite1.png);
    width: 155px;
    height: 48px;
    background-position: -601px -522px;
    background-size: 200px;
}

/*******************************************************************************************/
/****************************************** Frontpage Headline *****************************/
/*******************************************************************************************/

ul.frontpage-headline {
    display: table;
    padding-left: 0;
}

ul.frontpage-headline li {
    display: table-cell;
    text-align: left;
    padding: 0 3vw;
    box-sizing: unset;
    max-width: 175px;
}

ul.frontpage-headline li.lefty {
    text-align: center;
    background-image: url(/images/navigation_sprite1.png);
    background-repeat: no-repeat;
    background-position: right -292px;
}


ul.frontpage-headline li.centery {
    text-align: center;
    background-image: url(/images/navigation_sprite1.png);
    background-repeat: no-repeat;
    background-position: right -292px;
}

ul.frontpage-headline li.righty {
    text-align: center;
}

ul.frontpage-headline li .title {
    display: inline;
    font-size: clamp(1rem, 0.5rem + 2vw, 1.2rem);
    font-weight: 300;
    text-transform: uppercase;
}

ul.frontpage-headline li .value {
    display: inline;
    font-size: clamp(1rem, 1rem + 2vw, 1.8rem);
    color: #fff;
}


/*******************************************************************************************/
/***************************************** Main Footer *************************************/
/*******************************************************************************************/

.main_footer {
    text-align: left;
    width: 100%;
    min-width: 300px;
    background: #F7F6F2;
    padding-top: 2vh;
    padding-bottom: 2vh;
    min-height: 7vh;
}

.main_footer .column h2 {
    font-size: 16px;
    color: #555;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.2em;
}

.main_footer .column.last h2 {
    padding-right: 10px;
}

.main_footer .column h2 a {
    color: #555;
    text-decoration: none;
}

.main_footer .column h2 a:hover {
    color: #555;
}

.main_footer .column ul {
    width: 100%;
    margin-bottom: 0;
}

.main_footer .column ul li {
    font-size: 13px;
    color: #555;
    display: block;
}

.main_footer .column ul li a {
    display: block;
    color: #555;
    text-decoration: none;
    font-size: 0.8rem;
}


.main_footer .column ul li:hover a {
    color: #333;
}

.main_footer .column.first ul li span {
    vertical-align: top;
    display: inline-block;
}

.main_footer .column.first ul li {
    display: flex;
    align-items: center;
    padding-bottom: 1.5vh;
}

.main_footer .column.first ul li a {
    margin-left: 5px;

}

.main_footer .column.next ul {
    padding-bottom: 1.5vh;
}

.main_footer .column.next ul li .title {
    font-size: 1.2rem;
}

.footer_title {
    font-size: large;
}

#SIB_logo {
    width: 34px;
    height: 26px;
    background-image: url(/images/navigation_sprite1.png);
    background-position: -331px -1px;
    margin-left: 3vw;
}

#SIB_logo:hover {
    background-position: -368px -1px;
}

#CPR_logo {
    width: 61px;
    height: 26px;
    background-image: url(/images/navigation_sprite1.png);
    background-position: -331px -59px;
    margin-left: 3vw;
}

#CPR_logo:hover {
    background-position: -331px -30px;
}

#EMBL_logo {
    width: 26px;
    height: 29px;
    background-image: url(/images/navigation_sprite1.png);
    background-position: -395px -30px;
    margin-left: 3vw;
}

#EMBL_logo:hover {
    background-position: -395px -62px;
}

.horizontal_list {
    display: flex;
    flex-direction: row;
}

.button-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    font-weight: 900;
    font-size: 1.3rem;
}

.button-back-to-top:active {
    background-color: #a0a0a0;
}

/*******************************************************************************************/
/*************************************** Main Content **************************************/
/*******************************************************************************************/

.container {
    padding: 0;
    --bs-gutter-x: 0;
}

.menu_content {
    width: 100%;
    min-width: 300px;
    text-align: -webkit-center;
}

.menu_content h1 {
    text-align: center;
    display: block;
    font-size: clamp(1.5rem, 1rem + 2vw, 2rem);
    text-transform: uppercase;
    padding: 3vw;
    color: #7881af;
    font-weight: 700;
    margin: max(1.5vh, 1.5vw);
}

.menu_content h2 {
    width: 100%;
    text-align: left;
    display: block;
    font-size: 18px;
    color: #555;
}

.menu_content h1 .icon {
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    background-size: 34px auto;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
}

.row_100 {
    width: 100%;
}

/*******************************************************************************************/
/************************************* Search Menu ****************************************/
/*******************************************************************************************/

.search_menu_wrap {
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7vh 3vw 2vh 3vw;
    background: white;
    max-width: 800px;
}

.search_menu_wrap div {
    text-decoration: none;
    margin: max(1.5vh, 1.5vw);
    width: 100%;
    min-height: 40px;
}

.search_menu_wrap > .title {
    width: 100%;
    text-align: center;
    display: block;
    font-size: clamp(1.8rem, 1rem + 2vw, 2rem);
    text-transform: uppercase;
    padding: 0 3vw 10vw 3vw;
    color: #848ec2;
    font-weight: 700;
}

.search_menu_wrap button {
    width: 100%;
    text-align: left;
    font-size: clamp(1rem, 0.9rem, 1rem);
    color: #555;
    text-decoration: none;
    padding-left: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-bottom: 5px;
}

.search_menu_wrap button:active {
    background: #a0a0a0;
}

.search_menu_wrap button .title {
    font-weight: 700;
    font-size: clamp(1rem, 1.1rem, 1.2rem);
}

.search_menu_options_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.test {
    width: 36px;
    height: 36px;
    background: url(/images/navigation_sprite2.py.png) no-repeat -31px -248px;
    margin-right: 2vw;
}

/*******************************************************************************************/
/************************************* Search Forms ****************************************/
/*******************************************************************************************/

.search_input_wrap {
    display: block;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
}

.search_input_box {
    text-align: -webkit-center;
    min-height: 70vh;
    align-content: center;
}

.search_input_box .example_explainer {
    font-size: 12px;
}

.search_input_box h2 {
    font-weight: bold;
}

.category_menu > .box_title {
    width: 100%;
    text-align: left;
    display: block;
    font-size: 18px;
    padding-top: 3%;
    padding-bottom: 2%;
    color: #555;
}

.search_input_box > .box_title {
    width: 100%;
    text-align: center;
    display: block;
    font-size: clamp(1.8rem, 1rem + 2vw, 2rem);
    padding: 5vw 1vw 5vw 1vw;
    color: #848ec2;
    font-weight: 700;
}

.search_input_box > .box {
    padding: 3vw 3vw 8vw 3vw;
    max-width: 800px;
}

.wrap_search_item {
    position: relative;
    float: left;
    width: 100%;
}

.wrap_search_item span.organism_drop {
    width: 14px;
    height: 14px;
    opacity: 0.45;
    background-image: url(/images/navigation_sprite1.png);
    background-position: -405px -1px;
    display: inline-block;
}

.organism_drop_spinner {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    margin-left: -5px;
}

.organism_choice_box_dropdown_container {
    z-index: 200;
    position: absolute;
    top: 13px;
    right: 2px;
    width: 22px;
    vertical-align: middle;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 0;
}

.organism_choice_box_popup_div {
    z-index: 10000;
    box-sizing: border-box;
    display: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px solid #d6d6d6;
    background: white;
}

.organism_choice_box_select {
    color: #555;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    border-color: #CCC;
    border-width: 1px;
    border-style: solid;
    margin-top: -20px
}

.wrap_species_choice_box {
    position: relative;
    float: left;
    width: 100%;
}

.wrap_search_item .input {
    z-index: 10;
    position: relative;
    background: none;
    font-size: 1rem;
    margin-top: 5px;
    border-radius: 5px;
}

.wrap_search_item .inputsmall {
    z-index: 10;
    position: relative;
    background: white;
    font-size: 10px;
}

.wrap_search_item .input:focus {
    background: none;
}

.overlay_search_item {
    position: absolute;
    bottom: 50%;
    margin-bottom: -7px;
    right: 10px;
    height: 20px;
    line-height: 20px;
    color: #555;
    z-index: 0;
}

.main_content ul.instant_list {
    width: 100%;
    float: left;
    margin-top: 0;
}

.main_content ul.instant_list > li {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 10px;
    color: #555;
}

.main_content ul.instant_list li .red {
    color: #F34755;
}

.main_content ul.instant_list li > span {
    display: inline-block;
    float: left;
}

.main_content ul.instant_list li span.name {
    font-weight: 400;
    font-size: 13px;
    position: relative;
    width: 60%;
}

.main_content ul.instant_list li span.value {
    font-weight: 700;
    font-size: 13px;
    position: relative;
    width: 40%;
}

.main_content ul.instant_list li.total {
    padding-top: 5px;
    padding-bottom: 5px;
}

.main_content ul.instant_list li.total span.name {
    font-size: 16px;
}

.main_content ul.instant_list li.total span.value {
    font-size: 16px;
}

div.advanced_settings_wrapper {
    padding: 0;
    width: 100%;
    float: left;
    text-align: left;
    background-repeat: no-repeat;
    color: #555;
    margin-bottom: 5px;
    margin-top: 5px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background: #fff;
    box-sizing: border-box;
    transition: .2s linear all;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
}

div.advanced_settings_options {
    margin-top: 1vw;
    margin-bottom: 1vh;
    /* center the table inside this div*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.advanced_settings_options_labels {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    padding-right: 1vw;
}

/*******************************************************************************************/
/****************************************** Main Form Styles *******************************/
/*******************************************************************************************/

.row_50 .button, .button.full_width {
    width: 100%;
}

.button_with_spinner div {
    display: inline-block;
    width: 16px;
}

.button_with_spinner img {
    vertical-align: middle;
    margin-top: -3px;
}

.spinner_hidden img {
    display: none;
}

.spinner {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    background-image: url('/images/oversized_input_spinner.gif');
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
}

.wrap_input {
    margin-bottom: 10px;
    float: left;
    display: block;
    width: 100%;
}

.search_form_explain {
    padding: 10px 42px 10px 42px;
    border-bottom: 1px solid #d6d6d6;
    color: #555;
    margin-bottom: 7px;
    font-size: 13px;
    text-align: justify;
    line-height: 1.7;
}

.wrap_input_with_examples {
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
    float: left;
    display: block;
    width: 100%;
    text-align: right;
}

.input {
    min-height: 6vh;
    padding: 5px;
    width: 100%;
    float: left;
    text-align: left;
    background-repeat: no-repeat;
    color: #555;
    margin-bottom: 1vh;
    border: 1px solid #d4d4d4;
    background: #fff;
    box-sizing: border-box;
    transition: .2s linear all;
}

.input:focus {
    min-height: 6vh;
    padding: 5px;
    width: 100%;
    float: left;
    text-align: left;
    background-repeat: no-repeat;
    color: #555;
    margin-bottom: 1vh;
    border: 1px solid #d4d4d4;
    background: #fff;
    box-sizing: border-box;
    transition: .2s linear all;
    font-size: 1rem !important;
}

.tree_search_button {
    height: 22px;
    line-height: 23px;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    display: inline-block;
    color: #4d4d4d;
    border-bottom: none;
    background-color: rgba(230, 230, 230, 1);
    margin-left: 6px;
    margin-right: 6px;
    padding-bottom: 1px;
    border-radius: 3px;
    transition: all .05s ease-out .05s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.textarea {
    padding: 12px 10px;
    width: 100%;
    float: left;
    text-align: left;
    background-repeat: no-repeat;
    color: #555;
    margin-bottom: 5px;
    border: 1px solid #d4d4d4;
    background: #fff;
    min-height: 90px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    box-sizing: border-box;
    transition: .2s linear all;
}

.select {
    padding: 12px 10px;
    width: 100%;
    float: left;
    text-align: left;
    background-repeat: no-repeat;
    color: #555;
    margin-bottom: 5px;
    border: 1px solid #d4d4d4;
    background: #fff;
    box-sizing: border-box;
}

span.example_small {
    position: absolute;
    right: 0;
    /*top: 0.5vh;*/
    width: 100%;
    text-align: right;
    font-size: 1rem;
    color: #A0A0A0;
}

a.example_small {
    color: #A0A0A0;
    text-decoration: underline;
    cursor: pointer;
}

a.js_active {
    color: #A0A0A0;
    text-decoration: underline;
    cursor: pointer;
}

.input:hover, .textarea:hover {
    border: 1px solid rgba(0, 0, 0, .3);
}

.input:focus, .input.focus, .wrap_input.error .input:focus, .wrap_input.error .input.focus, .textarea:focus, .textarea.focus, .wrap_input.error .textarea:focus, .wrap_input.error .textarea.focus {
    color: #333;
    border-color: #848ec2;
    border-width: 1px;
    background: #fff;
    font-size: 12px;
}

.wrap_input.error .input, .wrap_input.error .textarea {
    border: 1px solid #F34755;
    background: #F7DEE0;
    margin-bottom: 5px;
}

.label {
    float: left;
    color: #555;
}

.as_label {
    float: right;
    text-align: end;
    width: 100%;
    color: #A5A5A5;
    margin-bottom: 1vw;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
}

.wrap_input .error_message {
    display: none;
    font-size: 12px;
    color: #F34755;
    clear: both;
}

.wrap_input.error .error_message {
    display: block;
}

.wrap_input.error .label {
    color: #F34755;
    font-weight: 700;
}

.wrap_checkbox {
    float: left;
    display: block;
    border-bottom: 1px solid #fff;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
}

.wrap_checkbox.last {
    border-bottom: none;
}

.checkbox {
    float: right;
}

.checkbox_label {
    float: left;
    width: 50%;
    color: #555;
    padding-top: 10px;
}

.copy_icon_button {
    position: relative;
    top: 4px;
    left: 6px;
    background: #c6cce7;
    border-radius: 6px;
    padding-top: 4.5px;
    padding-left: 5px;
    padding-right: 6px;
    padding-bottom: 2px;
    border: #848ec2 1px solid;
    transition: background 0.2s;
}

/*******************************************************************************************/
/****************************************** Buttons ****************************************/
/*******************************************************************************************/

button {
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #4d4d4d;
    border: 1px solid #A0A0A0;
    border-radius: 5px !important;
    vertical-align: middle;
}

.small_button {
    width: 12%;
    /*height: 25px;*/
}

.settings_button {
    width: 40%;
}

.active_button {
    background-color: #a0a0a0;
}

.large_submit_button {
    width: 100%;
    height: max(5vh, 5vw);
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background-color: #848ec2;
    margin-top: 2px;
    color: white
}

.large_submit_button:active {
    background-color: #B6C0F1;
    border-color: #848ec2;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 1), inset 0 2px 3px rgba(0, 0, 0, 0.1);
}

.large_submit_button_active {
    background-color: #B6C0F1;
    border-color: #848ec2;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 1), inset 0 2px 3px rgba(0, 0, 0, 0.1);
}

.filter_button {
    margin-bottom: 10px;
    border-radius: 3px !important;
    padding: 3px 15px 3px 15px;
}
.filter_button:active {
    background: #a0a0a0;
}


.bordered_button {
    border: 1px solid #A0A0A0 !important;
}

.show_button {
    min-width: 2rem;
    min-height: 2rem;
    margin: 1px;
    padding: 1px 5px 1px 5px;
    font-size: 1rem;
    /*font-weight: bold;*/
}

.show_button:active {
    background-color: #a0a0a0;
}

/********************************************************************************************/
/********************************** Explanatory Legends *************************************/
/********************************************************************************************/

div.legend_headline {
    text-align: left;
    font-weight: 700;
    font-size: 1rem;
    padding: 1vh;
    padding-left: 2%;
}

/********************************************************************************************/
/********************************** Netstat Analysis *************************************/
/********************************************************************************************/

.netstat_wrapper {
    margin-top: 15px;
    margin-bottom: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 4px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
}

.netstat_table {
    width: 96%;
    min-width: 300px;
    border: 1px solid #A0A0A0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    vertical-align: middle;
    margin: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    table-layout: fixed;
}

.netstat_table .center_cell {
    display: flex;
    justify-content: center;
    /*width: 49%*/
    padding: 10vw; /* Add padding for spacing */
    text-align: left; /* Left align the text */
}

.netstat_table .left_cell {
    display: table-cell;
    text-align: left;
    padding-right: 10vw;
}

.netstat_table .right_cell {
    display: table-cell;
    text-align: left;
    font-weight: 700;
}

.netstat_table tr {
    border-bottom: 1px solid #d6d6d6;
}

.ppi_enrichment_hint {
    padding-top: 10px;
    color: #555;
    font-style: italic;
    text-align: center;
}

.ppi_enrichment_hint a {
    color: inherit;
    text-decoration: underline;
}

.ppi_enrichment_hint span.emphasis {
    font-weight: bold;
}

/*******************************************************************************************/
/********************************** main page content **************************************/
/*******************************************************************************************/

div.main_page_content_container h1 {
    color: #848ec2;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

/*******************************************************************************************/
/************************************** Bottom Page Content Selectors **********************/
/*******************************************************************************************/

div.bottom_page_content_scrollmenu {
    overflow-y: hidden; /* Prevent vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap;
    height: max(6vw, 5vh);
    display: flex;
    align-items: center;
    margin-bottom: max(1.5vh, 1.5vw);
    padding-left: 2%;
    padding-right: 2%;
}

div.bottom_page_content_scrollmenu button {
    margin: 0.4vh;
    width: 20%;
    min-width: 100px;
    height: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.bottom_page_content_scrollmenu button:active {
    background-color: #a0a0a0;
}

.fading_menu_right {
    z-index: 50;
    width: 100%;
    position: absolute;
    height: max(6vw, 5vh);
    background: linear-gradient(to right, transparent, transparent calc(92%), rgba(255, 255, 255, 1));
    right: 0;
    pointer-events: none;
}

.fading_menu_left {
    z-index: 50;
    width: 100%;
    position: absolute;
    height: max(6vw, 5vh);
    background: linear-gradient(to left, transparent, transparent calc(92%), rgba(255, 255, 255, 1));
    right: 0;
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar-track {
    background: #F7F6F2; /* Color of the track */
}

::-webkit-scrollbar-thumb {
    background: #F7F6F2; /* Color of the thumb */
}

span.bottom_page_content_arrows {
    margin-left: 1vw;
    height: 15px;
    width: 13px;
    background-image: url(/images/navigation_sprite2.png);
    background-repeat: no-repeat;
    background-position: 0 -13px;
}

.bottom_page_content_scrollmenu button span.settings_icon {
    margin-right: 6px;
    height: 21px;
    width: 20px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -40px -1px;
    float: left;
    margin-top: 4px;
}

.bottom_page_content_scrollmenu button span.more_icon {
    margin-right: 7px;
    height: 21px;
    width: 20px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -60px -1px;
    float: left;
    margin-top: 4px;
}

.bottom_page_content_scrollmenu button span.less_icon {
    margin-right: 6px;
    height: 21px;
    width: 22px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -59px -22px;
    float: left;
    margin-top: 3px;
}

.bottom_page_content_scrollmenu button span.share_icon {
    margin-right: 6px;
    height: 21px;
    width: 22px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -138px -21px;
    float: left;
    margin-top: 3px;
}

.bottom_page_content_scrollmenu button span.info_icon {
    margin-right: 1.5vw;
    height: 16px;
    width: 16px;
    background-image: url(/images/navigation_sprite2.png);
    background-repeat: no-repeat;
    background-position: -17px -20px;
    float: left;
}

.bottom_page_content_scrollmenu button span.analysis_icon {
    margin-right: 6px;
    height: 25px;
    width: 20px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -81px -22px;
    float: left;
    margin-top: 8px;
}

.bottom_page_content_scrollmenu button span.nodes_icon {
    margin-right: 6px;
    height: 23px;
    width: 23px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -102px 1px;
    float: left;
    margin-top: 3px;
}

.bottom_page_content_scrollmenu button span.figure_icon {
    background-image: url(/images/tabs_icons_v3.svg);
    margin-right: 6px;
    height: 20px;
    width: 23px;
    background-repeat: no-repeat;
    background-position: -213px -22px;
    float: left;
    margin-top: 1px;
}

.bottom_page_content_scrollmenu button span.edges_icon {
    background-image: url(/images/tabs_icons_v3.svg);
    margin-right: 6px;
    height: 23px;
    width: 23px;
    background-repeat: no-repeat;
    background-position: -102px -20px;
    float: left;
    margin-top: 3px;
}

.search_icon {
    margin-right: 6px;
    height: 23px;
    width: 23px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -163px -1px;
    float: left;
    margin-top: 3px;
}

.desktop_icon {
    margin-right: 6px;
    height: 23px;
    width: 23px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -163px -21px;
    float: left;
    margin-top: 3px;
}

.help_icon {
    margin-right: 6px;
    height: 23px;
    width: 23px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -188px -0.9px;
    float: left;
    margin-top: 3px;
}

.download_icon {
    margin-right: 6px;
    height: 23px;
    width: 23px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -190px -21px;
    float: left;
    margin-top: 3px;
}

.my_icon {
    margin-right: 6px;
    height: 23px;
    width: 23px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -213px -1px;
    float: left;
    margin-top: 3px;
}

.bottom_page_content_scrollmenu button.active span.bottom_page_content_arrows {
    background-position: -1px -29px;
}

.bottom_page_content_scrollmenu button span.view_icon {
    margin-right: 6px;
    height: 18px;
    width: 22px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -17px -4px;
    float: left;
    margin-top: 8px;
}

/*******************************************************************************************/
/********************************** Data Settings Box **************************************/
/*******************************************************************************************/

div.data_settings_separator {
    border-bottom: 1px solid #A0A0A0;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.data_settings_separator_vertical {
    display: table-cell;
    border-left: 1px solid #A0A0A0;
    margin-left: 10px;
    margin-right: 10px;
}

div.data_settings_wrap * div.data_settings_body {
    padding: 0;
    color: #555;
    width: 100%;
}

div.data_settings_section_headline {
    color: #555;
    margin-bottom: 7px;
}

div.data_settings_label_active {
    color: #A00000 !important;
}

div.data_settings_basic_advanced_label {
    font-weight: bold;
    vertical-align: top;
}

div.data_settings_checkbox_wrap {
    display: table-cell;
    padding: 4px;
}

div.data_settings_select_wrap {
    display: table-cell;
    padding-right: 10px;
}

.data_settings_select {
    height: 27px;
    padding-left: 5px;
    padding-right: 17px;
    width: 100%;
    text-align: left;
    color: #555;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(/images/navigation_sprite2.png);
    background-position: right -467px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    transition: .1s linear all;
}

.data_settings_custom {
    height: 27px;
    padding-left: 5px;
    padding-right: 17px;
    width: 100%;
    text-align: left;
    color: #555;
    border: 1px solid #d4d4d4;
    background: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box;
    transition: .1s linear all;
}

.data_settings_select_large {
    height: 27px;
    padding-left: 5px;
    padding-right: 17px;
    width: 100%;
    text-align: left;
    color: #555;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(/images/navigation_sprite2.png);
    background-position: right -460px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    transition: .1s linear all;
}

.data_settings_search_large {
    height: 27px;
    padding-left: 5px;
    padding-right: 17px;
    width: 100%;
    text-align: left;
    color: #555;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    background: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box;
    transition: .1s linear all;
}

.data_settings_select_active {
    border: 1px solid #A00000 !important;
    color: #A00000 !important;
    transition: .1s linear all;
}

.data_settings_select:focus {
    color: #333;
    border-color: #848ec2;
    border-width: 1px;
}

.data_settings_input {
    height: 27px;
    width: 73.5%;
    text-align: left;
    background-repeat: no-repeat;
    color: #555;
    margin-top: 2px;
    margin-bottom: 2px;
    border: 1px solid #d4d4d4;
    background: #fff;
    box-sizing: border-box;
    transition: .2s linear all;
    border-radius: 0;
}

.data_settings_input_active {
    border: 1px solid #A00000 !important;
    color: #A00000 !important;
    transition: .2s linear all;
}

.data_settings_input:focus {
    color: #333;
    border-color: #848ec2;
    border-width: 1px;
    background: #fff;
}

div.checkbox_wrap {
    position: relative;
    height: auto;
}

input[type=checkbox]:not(old),
input[type=radio ]:not(old) {
    width: 30px;
    padding: 0;
    opacity: 0;
    float: left;
}

input[type=checkbox]:not(old) + label,
input[type=radio ]:not(old) + label {
    padding-left: 30px;
    line-height: 20px;
    display: block;
    min-height: 5vh;
    position: relative;
}

input[type=checkbox]:not(old) + label::before,
input[type=radio ]:not(old) + label::before {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url(/images/navigation_sprite2.png) no-repeat 0 -180px;
    content: '';
    left: 0;
}

input[type=checkbox]:not(old):hover + label::before,
input[type=radio]:not(old):hover + label::before {
    background: url(/images/navigation_sprite2.png) no-repeat 0 -138px;
}

input[type=checkbox]:not(old):checked + label::before {
    background: url(/images/navigation_sprite2.png) no-repeat 0 -265px;
}

input[type=checkbox]:not(old):hover:checked + label::before {
    background: url(/images/navigation_sprite2.png) no-repeat 0 -224px;
}

input[type=radio]:not(old):checked + label::before {
    background: url(/images/navigation_sprite2.png) no-repeat 0 -94px;
}

input[type=radio]:not(old):hover:checked + label::before {
    background: url(/images/navigation_sprite2.png) no-repeat 0 -52px;
}

/*******************************************************************************************/
/********************************** View Settings Box **************************************/
/*******************************************************************************************/

div#view_settings_wrap {
    border: 1px solid #A0A0A0;
}

div.view_settings_section_headline {
    color: #555;
    margin-bottom: 7px;
    /*font-weight: 600;*/
    font-size: 1.1rem;
}

div.view_settings_separator {
    border-bottom: 1px solid #A0A0A0;
    margin-top: 10px;
    margin-bottom: 10px;
}

div#view_settings_body {
    padding: 15px;
    color: #555;
}

div.view_settings_label {
    font-size: 10px;
}

div.view_settings_checkbox_wrap {
    display: table-cell;
    padding: 4px;
}

div.view_settings_select_wrap {
    display: table-cell;
    padding-right: 10px;
}

.network_size_change_button {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    color: #555;
    font-size: 12px;
    text-align: center;
    cursor: default;
    font-weight: 500;
    margin: 6px;
    box-sizing: border-box;
    padding-right: 5px;
    background-color: rgba(230, 230, 230, 1);
    border-radius: 3px;
}

div.network_size_change_label {
    min-height: 28px;
    line-height: 28px;
    font-size: 10px;
    transition: .1s linear all;
}

div.network_size_change_label_active {
    min-height: 28px;
    line-height: 28px;
    font-size: 10px;
    color: #A00000;
    transition: .1s linear all;
}

span.network_size_change_label {
    margin-left: 18px;
    font-weight: 700;
    transition: .1s linear all;
}

span.network_size_change_label_active {
    margin-left: 18px;
    font-weight: 700;
    color: #A00000;
    transition: .1s linear all;
}

/*******************************************************************************************/
/*********************************** Error / Info Boxes  ***********************************/
/*******************************************************************************************/

.error_info_wrap {
    margin: 0 auto;
    background: #fff;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 4px;
}

.error_info_box > .box_title {
    border-bottom: 1px solid #d6d6d6;
    text-align: left;
    display: block;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10%;
    padding-right: 10%;
    color: #848ec2;
    font-weight: 700;
    font-size: 26px;
}

.error_info_box > .box_body {
    padding-bottom: 40px;
    font-size: clamp(1rem, 2vw, 1rem);
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
    color: #4D4D4D;
}

div.error_info_message {
    text-align: left;
    padding: 10px;
    color: #555;
}

div.error_info_separator {
    height: 20px;
}

.error_info_button {
    width: 60%;
    min-width: 100px;
}

a.error_linkout {
    color: #848ec2;
    font-weight: bold;
}

/*******************************************************************************************/
/*********************************** Responsive filtrer ******************************************/
/*******************************************************************************************/


.filter {
    position: relative;
    display: flex;
    justify-content: flex-end; /* Align the button to the right */
    width: 98%;
}

div.filter__dropdown_button {
    text-align: left !important;
    font-size: 1rem;
    padding: 5px 10px 5px 10px;
}

div.filter__dropdown_button:hover {
    background-color: #848ec2;
}

.filter__button:focus {
    outline: 1px auto -webkit-focus-ring-color;
}

.filter__dropdown {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    width: 100%;
    z-index: 50;
    position: absolute;
    margin-top: 10.5vh;
    background-color: white;
    right: 0;
}

.filter__dropdown__actions {
    border-top-width: 1px;
    border-top-color: #e4e7eb;
    border-top-style: solid;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 0.5rem 0.25rem 0.5rem;
}

.filter__dropdown__actions button {
    border-radius: 0.125rem;
    padding: 0.25rem 0.5rem;
    background-color: #2b7de9;
    border-color: transparent;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.filter-modal {
    display: block;
    background-color: transparent;
}

/*@media (min-width: 768px) {*/
/*    .filter-modal {*/
/*        display: none;*/
/*    }*/
/*}*/

.filter-modal__wrapper {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 1vh;
    overflow: auto; /* Enable scrolling */
}

.filter-modal__header {
    display: flex;
    padding: 0.5rem 0.5rem;
    border: 1px #e4e7eb solid;
    justify-content: flex-end;
    margin-bottom: 5vw;
    background: #E6E6E6;
}

.filter-modal__content {
    display: flex;
    flex-direction: column;
    padding-left: 3vh;
    padding-right: 3vh;
}

.filter-modal__content div {
    border-bottom: 1px #e4e7eb solid;
    padding-top: 2vh;
}

.filter-modal__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;
}

.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.4);
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    display: block;
    color: #000000;
    padding: 5px;
    text-decoration: none;
}

.dropdown-content a:hover {
    color: #FFFFFF;
    background-color: #00A4BD;
}

.floatingdivcloselink {
    background-image: url(/images/floating_close_icon.png);
    width: 16px;
    height: 16px;
    color: #000000;
    opacity: 0.3;
    border: transparent;
}

.floatingdivcloselink:hover {
    opacity: 0.7;
}

.movable_div_title_bar {
    width: 80vw;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
}

.movable_div_closelink_wrapper {
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
}

/*******************************************************************************************/
/***********************************  interaction page   ******************************************/
/*******************************************************************************************/
.edge_page_wrap {
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2%;
    max-width: 800px;

}

.interaction_title {
    text-align: left;
    font-size: clamp(1rem, 2vw, 1.2rem);
    color: #555;
    font-weight: 400;
    text-transform: uppercase;
}

.interaction_column_title {
    color: #6D6D6D;
    font-weight: bold;
    padding: 3vw;
    text-align: left;
}

.interaction_column_data {
    padding: 3vw;
    text-align: left;
}

.interaction_column_data a {
    color: #333;
    text-decoration: underline;
    border: 3px #f7f6f2;
    padding: 2.5vw;
    border-radius: 4px;
}

.edge_organism_wrap {
    color: #4d4d4d;
    font-weight: 600;
    text-align: left;
    font-size: 1rem;
    padding-top: 5vw;
}

.edge_organism_wrap a {
    color: #555;
}

.edge_organism_wrap button:active{
    background-color: #a0a0a0;
}

.confidence_score {
    padding: 3vw 0 3vw 0;
    text-align: left;
}

.interaction_selector {
    text-align: center;
    font-size: 18px;
    color: #555;
    border-bottom: solid 1px #A0A0A0;
    display: flex;
    justify-content: left;
}

.interaction_selected {
    background-color: #a0a0a0 !important;
    cursor: default;
}

.interaction_page_network_type_switch {
    line-height: 30px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 5px;
    padding-right: 9px;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    display: block;
    color: black; /*#4d4d4d;*/
    background-color: #e6e6e6;
    border-radius: 5px 5px 0 0;
    border: 1px solid #A0A0A0;
    border-bottom: 0;
    cursor: pointer;
    transition: all .05s ease-out .05s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.interaction_table {
    max-width: 100%;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    background-color: white;
}

.interactor_title {
    color: #333;
    font-size: clamp(1rem, 2vw, 1.2rem);
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.interactor_header {
    padding: 1vw;
    background: #EFEFEF;
    color: #4d4d4d;
    font-weight: 700;
    text-align: left;
    align-content: center;
    font-size: 0.89rem;
}

#arrow{
    /*margin-right: 6px;*/
    height: 21px;
    width: 38px;
    background-image: url(/images/tabs_icons_v3.svg);
    background-repeat: no-repeat;
    background-position: -129px 0;
    float: left;
    margin-top: 4px;
}

/*******************************************************************************************/
/***********************************  node page   ******************************************/
/*******************************************************************************************/

.set_interaction_table {
    display: table;
    border-collapse: collapse;
    width: 100%;
}

.data_white_table_row {
    font-size: 14px;
    background-color: white;
    border: 1px solid #A0A0A0;
    border-radius: 4px;
    padding: 10px;
    text-align: left;
    vertical-align: middle;
}

.interactors_table {
    width: 100%;
    max-width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px;
    border: 1px solid #A0A0A0;
}

Table.interactors_table Thead {
    color: #6D6D6D;
    font-weight: bold;
    padding: 5px;
    border: 1px solid #A0A0A0;
    border-right: 5px;
    text-align: left;
}

Table.interactors_table Tbody Tr {
    border: 1px solid #A0A0A0;
    background-color: white;
    border-bottom: 1px solid #A0A0A0;
}

.cell_large {
    padding: 3vw 0 3vw 3vw;
    width: 80vw;
}

.cell_small {
    padding: 2vw;
}

Table.interactors_table thead {
    height: 40px;
    padding: 2vw;
    border-radius: 5px;
}


.interactors_table .row_wrap {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #A0A0A0;
    align-items: center;
    background: white;
}

.interactors_table .last_row_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    border-radius: 5px;
}

.node_rounded_header {
    padding-left: 5px;
    background: #EFEFEF;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #A0A0A0;
}

.notselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

#svg_protein_image {
    position: relative;
}

/*******************************************************************************************/
/***********************************  organism page   ******************************************/
/*******************************************************************************************/

.organism_column_data {
    padding: 3vw;
    text-align: left;
    border-bottom: 1px solid #A0A0A0;
}

.link_wrap {
    padding: 3vw;
    text-align: left;
    border-bottom: 1px solid #A0A0A0;
}

.link_wrap a {
    color: #555;
}

.link_wrap button:active {
    background-color: #a0a0a0;
}

.organism_table {
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    background-color: white;
    max-width: 100%;
    width: 100%;
    text-align: left;
}

/*******************************************************************************************/
/***********************************  viewers table   ******************************************/
/*******************************************************************************************/

.evidence_view_choices_item {
    width: 96%;
    min-width: 300px;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    vertical-align: middle;
    margin: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.evidence_view_choices_item tr {
    border-bottom: 1px solid #d6d6d6;
}

.evidence_view_choices_item td {
    padding: 8px;
}

.evidence_view_choices_item .icon_wrapper {
    width: 100px;
    height: 55px;
    background-color: #E6E6E6;
    border-radius: 4px;
    float: left;
    position: relative;
    margin-right: 9px;
    padding: 40px;
    border: 1px solid #A0A0A0;
}

.evidence_view_choices_item .icon_wrapper:active {
    background-color: #a0a0a0;
}


.evidence_view_choices_item .icon {
    width: 90px;
    height: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -22.5px -45px;
    background-image: url(/images/navigation_sprite2.png);
    background-repeat: no-repeat;
    background-size: 140%;
}

.evidence_view_choices_item .Experiments {
    background-position: -7px -755px;
}

.evidence_view_choices_item .Databases {
    background-position: -13px -641px
}

.evidence_view_choices_item .Textmining {
    background-position: -12px -519px;
    height: 55px;
}

.evidence_view_choices_item .Neighborhood {
    background-position: -12px -385px;
    height: 54px;
    background-size: 135%;
}

.evidence_view_choices_item .Fusion {
    background-position: -13px -695px;
    height: 55px;
}

.evidence_view_choices_item .Cooccurrence {
    background-position: -4px -492px;
    height: 54px;
    background-size: 120%;
}


.evidence_view_choices_item .Coexpression {
    background-position: -12px -455px;
    height: 55px;
}


.evidence_view_choices_item a {
    color: #333;
    white-space: nowrap;
    margin: 1%;
}

/*******************************************************************************************/
/******************************************* Carousel ****************************************/
/*******************************************************************************************/

.carousel {
    width: 100%;
    height: 170px;
    margin: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 25vh;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #3498db;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
}

.carousel-indicators {
    margin-bottom: 3rem;
}

.indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ddd;
    margin: 0 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.indicator.active {
    background-color: #848ec2;
}

.notification {
    position: fixed;
    top: 100px;
    left: 0;
    background-color: #848ec2; /* Green */
    color: white;
    width: 96%;
    margin: 2%;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: opacity 0.5s ease;
}


/*******************************************************************************************/
/***************************** enrichment graph settings ***********************************/
/*******************************************************************************************/

table.enrichmentGraphSettings {
    width: 96%;
    margin: 2%;
    /*font-size: 0.7rem;*/
}

.graph-container {
    transition: transform 0.2s, background-color 0.2s;
    border: 1px solid #d6d6d6;
    margin: 2%;
    padding: 2%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.graph-container:active {
    transform: scale(0.98); /* Slight "pressed" effect */
    background-color: rgba(0, 0, 0, 0.1); /* Light overlay */
}